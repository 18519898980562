import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { pxToRem } from "../../utilities/converters"
import { P } from "../typography"
import Wordmark from "./wordmark"

const NavbarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Navbar = styled.div`
  width: 100%;
  max-width: ${pxToRem(980)}rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`

const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const NavLink = styled(P)`
  margin-left: ${pxToRem(48)}rem;
  border-bottom: ${props => (props.active ? '2px solid #333' : 0)};
`

const Header = ({active}) => {
  const links = ["Events", "Branding", "Contact"]

  return (
    <NavbarContainer>
      <Navbar>
        <Wordmark />
        <LinkContainer>
          {links.map(l => {
            return <NavLink active={l == active ? true : false}>{l}</NavLink>
          })}
        </LinkContainer>
      </Navbar>
    </NavbarContainer>
  )
}

export default Header
