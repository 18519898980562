import React from "react"
import styled from "styled-components"
import { H1 } from "../../components/typography"
import { pxToRem } from "../../utilities/converters"

const WordmarkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: ${pxToRem(60)}rem;
`

export default () => (
  <WordmarkContainer>
    <H1>mp.</H1>
  </WordmarkContainer>
)
